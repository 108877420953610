import { useCallback } from 'react'
import { SearchBuilder } from '@/logic/BackendSearch'
import { getPartnershipsCookie } from '@/utils/cookies'
import { useRouter } from 'next/router'
import api from '@/services/api'

import useAnalytics from '@/hooks/analytics'
import useCurrency from '@/hooks/currency'

// Assets
import destinations from '@/assets/best_prices.json'

const DestinationCard = ({
  origin,
  destination,
  cityName,
  outboundDate,
  inboundDate,
  price,
}) => {
  const partner = getPartnershipsCookie()
  const router = useRouter()
  const { track } = useAnalytics()
  const { formatCurrency } = useCurrency()

  const executeSearch = useCallback(() => {
    track('bestprices_search_launched', {
      origin: origin,
      destination: destination,
    })

    const search = new SearchBuilder('bundle')
      .withPartner(partner)
      .addPassenger('adult')
      .withRoundTrip(origin, destination, outboundDate, inboundDate)
      .build()

    api.search.createSearch(search).then(({ data }) => {
      if (data?.id) {
        const query = { id: data.id }
        router.push({
          pathname: '/search/[id]',
          query,
        })
      }
    })
  }, [origin, destination, outboundDate, inboundDate, router, partner?.name])

  return (
    <button
      onClick={executeSearch}
      className="bg-white cursor-pointer flex justify-between items-center border-b border-dark-10 pb-16"
    >
      <div className="flex flex-col items-start">
        <span className="text-dark font-bold text-14">{cityName}</span>
        <span className="text-dark-60 text-12">
          {origin} - {destination}
        </span>
      </div>
      <span className="price text-14 text-primary font-bold">
        A partir de {formatCurrency(Math.round(price * 1.2), 'EUR')}
      </span>
    </button>
  )
}

export default function BestPrices() {
  return (
    <div className="px-20 pt-30 border-t border-dark-10">
      <p className="text-[13px] font-bold text-dark-60 mb-10 uppercase mb-18 w-[60%] leading-[21px]">
        Les destinations prisées au{' '}
        <span className="text-primary">mois d&apos;août</span> par les{' '}
        <span className="text-primary">voyageurs Ulysse</span>
      </p>

      <div className="flex flex-col gap-16">
        {destinations.map((destination) => (
          <DestinationCard
            key={
              'destination-' +
              destination.origin_iata +
              '-' +
              destination.destination_iata
            }
            origin={destination.origin_iata}
            destination={destination.destination_iata}
            cityName={destination.city_name}
            price={destination.best}
            outboundDate={destination.departure_date}
            inboundDate={destination.return_date}
          />
        ))}
      </div>
    </div>
  )
}
