import clsx from 'clsx'
import AirportSearchMobile from '@/components/search/AirportSearchMobile'
import DatesMobile from '@/components/search/DatesMobile'
import PassengersMobile from '@/components/search/PassengersMobile'
import Modal from '@/components/ui/modal'
import { useState, useMemo, useEffect, useCallback } from 'react'
import { getCookie } from '@/utils/cookies'

// assets
import Chevron from '@/assets/svg/chevron.svg'
import DoubleArrows from '@/assets/svg/switch-arrows.svg'

import { nextMonth, getDays } from '@/logic/ContextualCalendarCache'
import { nextVersion, useMonotonicState } from '@/v2/common/monotonic-state'
import useDates from '@/hooks/dates'
import Button from '../ui/button'
import { useRouter } from 'next/router'
import { MAINTENANCE } from '../constants'
import useTranslation from '@/hooks/translation'

const steps = {
  form: 0,
  multi: 1,
  origin: 2,
  destination: 3,
  dates: 4,
  passengers: 5,
}

function formatAirport(airport) {
  if (airport) {
    if (!airport.type) {
      const s = airport.subLabel ? airport.subLabel.split(' ') : []
      return s.length > 1 ? (
        <>
          <span>{s.slice(0, -1).join(' ')}</span>&nbsp;
          <span className="text-[14px] text-black-50">{s[s.length - 1]}</span>
        </>
      ) : (
        airport.subLabel
      )
    } else {
      const label =
        airport.label === 'allAirports' || airport.type == 'airport'
          ? airport.subLabel
          : airport.label

      const s = label ? label.split(',') : []
      return s.length > 1 ? (
        <>
          <span>{s.slice(0, -1).join(',')}</span>&nbsp;
          <span className="text-[14px] text-black-50">{s[s.length - 1]}</span>
        </>
      ) : (
        label
      )
    }
  }
  return ''
}

function formatDates(from, to, formatDate) {
  if (from && to) {
    return (
      formatDate(
        from,
        from.getFullYear() === to.getFullYear() ? 'EEE d MMM' : 'EEE d MMM YYY'
      ) +
      ' - ' +
      formatDate(to, 'EEE d MMM YYY')
    )
  } else if (from) {
    return formatDate(from, 'EEE d MMM YYY')
  }
  return null
}

// ------------------------------------------------------------>

/**
 * Each form inputs are just buttons
 */
function FormButton({
  title,
  onClick,
  isSelect,
  roundedTop = true,
  roundedBottom = true,
  subLabel,
  children,
}) {
  return (
    <div
      className={clsx(
        'w-full min-h-[75px] bg-white flex justify-between items-center px-18 py-14 cursor-pointer',
        {
          'rounded-t-20': roundedTop,
          'rounded-b-20': roundedBottom,
          'border-b border-dark-10': roundedTop && !roundedBottom,
        }
      )}
      onClick={onClick}
    >
      <div className="flex flex-col flex-grow justify-center">
        <span className={clsx('label text-black-50', children && 'text-15')}>
          {title}
        </span>
        <span className="value font-bold text-black">{children}</span>
      </div>
      {subLabel && <div className="text-black-50 text-17">{subLabel}</div>}
      {isSelect && (
        <div className="text-black-50 text-20 font-bold w-20">
          <Chevron />
        </div>
      )}
    </div>
  )
}

/**
 * The submit form button
 */
function SubmitButton({ title, onClick, isSubmitting }) {
  return (
    <Button
      onClick={onClick}
      loading={isSubmitting}
      color="primary-rounded"
      className={clsx(
        'validate w-full min-h-[75px] text-white text-22 font-bold bg-primary'
      )}
      disabled={MAINTENANCE}
    >
      <span className="text-22">{title}</span>
    </Button>
  )
}

function SelectionModal({ search, step, setStep }) {
  const sliceId = search.slices[0].id
  const slice = useMemo(
    () => search.slices.find((S) => S.id === sliceId),
    [search.slices, sliceId]
  )

  const origin = slice?.origin
  const destination = slice?.destination
  const [departureDate, setDepartureDate] = useState(slice?.date)
  const [contextualDays, setContextualDays] = useMonotonicState({})

  const dates = search.getDates(sliceId)

  // the contextual days
  const updateEffect = async () => {
    // we can't search if no destination and no origin
    if (!origin || !destination) {
      return
    }

    const start = new Date()
    const end = nextMonth(start, 6)

    const v = nextVersion()
    const r = await getDays(
      origin.value,
      destination.value,
      search.tripType,
      search.partnership,
      departureDate,
      start,
      end
    )
    setContextualDays(r || {}, v)
  }

  useEffect(() => {
    updateEffect()
  }, [search.tripType, search.partnership, departureDate, origin, destination])

  const selectAirports = useCallback(
    (val) => {
      if (step === steps.origin) {
        search.setOrigin(val, sliceId)
      } else if (step === steps.destination) {
        search.setDestination(val, sliceId)
      }
      setStep(steps.form)
    },
    [step, search]
  )

  const selectDates = useCallback(
    (dates) => {
      search.setDatesAndTripType(dates, sliceId)
      goBack()
    },
    [search, sliceId]
  )

  const selectPassengersAndClass = useCallback(() => {
    setStep(steps.form)
  }, [])

  const goBack = useCallback(() => {
    setStep(steps.form)
  }, [])

  return (
    <Modal isVisible={step !== steps.form} isClosable={false} onClose={goBack}>
      {() =>
        step === steps.origin || step === steps.destination ? (
          <AirportSearchMobile
            isOrigin={step === steps.origin}
            origin={slice?.origin}
            destination={slice?.destination}
            onOriginClicked={() => setStep(steps.origin)}
            previous={goBack}
            onSelect={selectAirports}
          />
        ) : step === steps.dates ? (
          <DatesMobile
            previous={goBack}
            contextualData={contextualDays}
            isRange={true}
            selectedDates={dates}
            onSelect={selectDates}
            minDate={search.getMinDate(sliceId)}
            onDepartureDateChange={setDepartureDate}
          />
        ) : step === steps.passengers ? (
          <PassengersMobile
            previous={goBack}
            numAdults={search.numAdults}
            numChildren={search.numChildren}
            numBabies={search.numBabies}
            onNumAdultsChange={search.setNumAdults}
            onNumChildrenChange={search.setNumChildren}
            onNumBabiesChange={search.setNumBabies}
            cabinClass={search.cabinClass}
            onCabinClassChange={search.setCabinClass}
            onConfirm={selectPassengersAndClass}
            buttonText={'Valider'}
          />
        ) : null
      }
    </Modal>
  )
}

// -------------------------------------------------------------->

export default function SearchComponent({ search, onCreate }) {
  const [step, changeStep] = useState(steps.form)
  const { formatDate } = useDates()
  const router = useRouter()
  const [inversed, setInversed] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { t } = useTranslation()

  // change the step only if we're not submitting
  const goto = useCallback(
    (step) => {
      if (!isSubmitting || step === 'form') {
        changeStep(step)
      }
    },
    [isSubmitting]
  )

  const switchAirports = useCallback(() => {
    search.setOrigin(search.slices[0]?.destination, search.slices[0]?.id)
    search.setDestination(search.slices[0]?.origin, search.slices[0]?.id)
    setInversed((prev) => !prev)
  }, [search])

  const formatedDate = useMemo(() => {
    if (search.slices.length) {
      const from = search.slices[0]?.date
      const to = search.slices[1]?.date

      if (from && to && search.tripType == 'roundtrip') {
        return formatDates(from, to, formatDate)
      } else if (search.tripType == 'oneway' && from) {
        return formatDates(from, null, formatDate)
      }
    }
    return null
  }, [search])

  const submitForm = useCallback(() => {
    if (search.isValid) {
      setIsSubmitting(true)
      search
        .createSearch({
          ignoreSpecialOffers: getCookie('ulysse:partnerships') ? false : true,
        })
        .then(({ data }) => {
          if (data?.id) {
            onCreate?.(data)

            const query = { id: data.id }
            router.push({
              pathname: '/search/[id]',
              query,
            })
          } else {
            setIsSubmitting(false)
          }
        })
        .catch((err) => {
          console.error(err)
          setIsSubmitting(false)
        })
    } else {
      goto(steps.form)
    }
  }, [search, router])

  return (
    <>
      <SelectionModal search={search} step={step} setStep={goto} />
      <div className="search-form flex flex-col gap-10">
        <div className="form-section origin-destination relative">
          <FormButton
            title={t('components.formMobile.origin')}
            subLabel={search.slices[0].origin?.value}
            roundedBottom={false}
            onClick={() => goto(steps.origin)}
          >
            {formatAirport(search.slices[0].origin)}
          </FormButton>
          <FormButton
            title={t('components.formMobile.destination')}
            subLabel={search.slices[0].destination?.value}
            roundedTop={false}
            onClick={() => goto(steps.destination)}
          >
            {formatAirport(search.slices[0].destination)}
          </FormButton>
          <Button
            className={clsx(
              'flex items-center justify-center w-40 h-40',
              'bg-primary-dark text-white shadow-text rounded-full',
              'absolute z-20 top-1/2 right-10 transform -translate-y-1/2',
              'duration-500',
              {
                'rotate-90': inversed,
                '-rotate-90': !inversed,
              }
            )}
            unstyled
            onClick={switchAirports}
          >
            <DoubleArrows />
          </Button>
        </div>
        <div className="form-section date">
          <FormButton
            title={t('components.formMobile.dates')}
            onClick={() => goto(steps.dates)}
          >
            {formatedDate}
          </FormButton>
        </div>
        <div className="form-section passengers">
          <FormButton
            title={t('components.formMobile.travelers')}
            value={search.shortPassengersCabinClassDisplay}
            onClick={() => goto(steps.passengers)}
            isSelect
          >
            {search.shortPassengersCabinClassDisplay}
          </FormButton>
        </div>
        <div className="form-section validation">
          <SubmitButton
            title="C'est parti !"
            onClick={submitForm}
            isSubmitting={isSubmitting}
          />
        </div>
      </div>
    </>
  )
}
