import Collapsible from '@/components/ui/collapsible'
import Button from '@/components/ui/button'
import Link from 'next/dist/client/link'

const reasons = [
  {
    id: '1',
    reason: (
      <p>
        Une interface <span className="text-primary">tellement simple</span>,
        comme vous n&apos;en avez jamais vu
      </p>
    ),
    description: (
      <div className="flex flex-col gap-10">
        <p>
          Nous soignons chaque étape pour proposer un site clair, concis et sans
          surprises pour réserver vos vols en 2 temps 3 mouvements.
        </p>
        <p>
          Pas de publicité, pas de frais cachés, rien que vous et votre projet
          (si vous avez besoin d&apos;aide on est là bien sûr, toujours à votre
          écoute).
        </p>
      </div>
    ),
  },
  {
    id: '2',
    reason: (
      <p>
        Un service client <span className="text-primary">ultra réactif</span>
      </p>
    ),
    description: (
      <div className="flex flex-col gap-10">
        <p>
          Toute une équipe est disponible pour vous accompagner dans les bons et
          les moins bons moments.
        </p>
        <p>
          Pour vous fournir le meilleur nous prévilégions la reactivité. Le
          temps de réponse moyen est sous la demi-heure.
        </p>
        <p className="font-bold text-primary">
          Essayez de nous écrire, allez-y ;){' '}
        </p>
      </div>
    ),
  },
  {
    id: '3',
    reason: (
      <p>
        Des <span className="text-primary">prix réduits</span> pour vous qui
        venez du Petit futé.
      </p>
    ),
    description: (
      <div className="flex flex-col gap-10">
        <p>
          Nous avons à coeur de prendre soin de tout le monde (mais on doit bien
          l&apos;avouer, nous avons une petite préférence pour vous).
        </p>
      </div>
    ),
  },
  {
    id: '4',
    reason: (
      <p>
        La <span className="text-primary">conciergerie du voyageur</span>{' '}
        disponible dès le premier achat
      </p>
    ),
    description: (
      <div className="flex flex-col gap-10">
        <p>
          Chez Ulysse vous n&apos;achetez pas un vol, mais un{' '}
          <span className="text-primary">accompagnement complet</span>.
        </p>
        <p>
          Nous proposons de vous aider pour chaque étape de votre voyage, pour
          que l&apos;inoubliable ne se résume qu&apos;aux chouettes moments
          vécus.
        </p>
        <p>
          Le moment est venu de voyager enfin{' '}
          <span className="text-primary">sans prise de tête.</span>
        </p>
      </div>
    ),
  },
]
/*
              Une interface simple comme vous n’en avez jamais vu Un support
              réactif (essayez de leur écrire, allez-y ;)) Toute une équipe pour
              vous accompagner dans les bons et mauvais moments Des pris
              réduits, pour vous qui venez du petit futé :wink: Une conciergerie
              dès le premier achat pour s’occuper de TOUS les à-côtés qu’un
              voyage amène*/

export default function PetitFuteReassurance() {
  return (
    <section className="bg-white container py-30 md:py-50">
      <h4 className="mb-20 font-bold leading-none text-26">
        4 bonnes raisons de tenter{' '}
        <span className="text-primary">l&apos;expérience Ulysse&nbsp;</span>:
      </h4>
      <div className="flex flex-col gap-15">
        {reasons.map(({ id, reason, description }) => (
          <Collapsible
            key={'reason-' + id}
            isFullWidth
            trigger={
              <Button
                className="flex flex-row-reverse items-baseline w-full font-bold cursor-pointer text-left gap-10"
                unstyled
              >
                <span className="flex-grow">{reason}</span>
              </Button>
            }
          >
            <div className="p-10 pl-20">{description}</div>
          </Collapsible>
        ))}
        <p className="mt-20">
          N&apos;hésitez pas à nous écrire, nous serons ravis de vous
          aider&nbsp;! Préparer un voyage est fastidieux, alors si on peut se
          donner un coup de pouce, ce serait dommage de s&apos;en passer&nbsp;!
        </p>
        <p>
          Vous cherchez un peu d&apos;inspiration&nbsp;? Vous avez jeté un coup
          d&apos;oeil à nos{' '}
          <Link href="/guides">
            <a className="text-primary">guides</a>
          </Link>
          &nbsp;?
        </p>
      </div>
    </section>
  )
}
