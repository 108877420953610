import useTranslation from '@/hooks/translation'
import useAnalytics from '@/hooks/analytics'

import Link from 'next/link'
import Image from 'next/legacy/image'

import Arrow from '@/assets/svg/arrow.svg'
import Confetti from '@/assets/img/messages/confetti.png'
import GiftCard from '@/assets/img/messages/giftcard.png'
import clsx from 'clsx'

export default function GiftCardHome({ isLight }) {
  const { t } = useTranslation()
  const { track } = useAnalytics()

  return (
    <div className="flex min-w-[300px] md:min-w-auto md:max-w-[300px] lg:max-w-auto">
      <Link
        href="/gift?theme=christmas"
        onClick={() => track('giftcard_clicked_homepage')}
        className={clsx(
          'relative group rounded md:text-white shadow-md w-full',
          isLight ? 'md:bg-white-20' : 'md:bg-dark-30',
          'hover:shadow-lg hover:-translate-y-2 motion-safe:transition motion-safe:duration-300 ease-out'
        )}
      >
        <div className="relative py-15 px-20 w-10/12 z-[1]">
          <p className="opacity-80 text-14">
            {t('pages.index.giftCardBlock.pretitle')}
          </p>
          <p className="font-bold text-14 mt-5">
            {t('pages.index.giftCardBlock.desc')}
          </p>
          <p className="font-bold flex items-center text-14 mt-15">
            {t('pages.index.giftCardBlock.cta')}
            <Arrow className="group-hover:translate-x-5 transform fill-white ml-5 transition duration-300 ease-out" />
          </p>
        </div>
        <div className="absolute top-0 right-0 bottom-[60%] left-[60%] z-0">
          <Image
            src={Confetti}
            layout="fill"
            objectFit="contain"
            objectPosition="top right"
            alt=""
          />
        </div>
        <div className="absolute bottom-0 right-0 top-[50%] left-[50%] z-0">
          <Image
            src={GiftCard}
            layout="fill"
            objectFit="contain"
            objectPosition="bottom right"
            alt="Gift card"
          />
        </div>
      </Link>
    </div>
  )
}
