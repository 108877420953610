import useTranslation from '@/hooks/translation'
import clsx from 'clsx'

export default function AirportEarlyHome({ isLight }) {
  const { t } = useTranslation()
  return (
    <div className="flex min-w-[300px] md:min-w-auto md:max-w-[300px] lg:max-w-auto">
      <div
        className={clsx(
          'group rounded py-15 px-20 md:text-white flex flex-col justify-between shadow-md',
          isLight ? 'md:bg-white-20' : 'md:bg-dark-30',
          'hover:shadow-lg hover:-translate-y-2 motion-safe:transition motion-safe:duration-300 ease-out'
        )}
      >
        <div>
          <p className="opacity-80 text-14">
            {t('pages.index.koalaBlock.pretitle')}
          </p>
          <p className="font-bold text-14 mt-5">
            {t('pages.index.koalaBlock.desc')}
          </p>
        </div>
      </div>
    </div>
  )
}
