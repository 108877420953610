// Describe a search as expected on the backend

const DEFAULT_CRITERIA = {
  sorting_rule: 'sort_by_price',
  ecological: false,
  only_checked_bag_included: false,
  only_direct_flight: false,
  only_short_stop: false,
  only_free_cancellation: false,
  only_free_modification: false,
  max_stopover: null,
  departure_hour: [],
  arrival_hour: [],
  include_airlines: null,
  exclude_airlines: null,
  include_airports: null,
  exclude_airports: null,
  exclude_airplane_manufacturers: null,
}

export class CriteriaBuilder {
  constructor(from = {}) {
    this.values = {
      ...DEFAULT_CRITERIA,
      ...from,
    }
  }

  with(name, value) {
    if (DEFAULT_CRITERIA[name] !== undefined) {
      this.values[name] = value
    }
    return this
  }

  reset(name) {
    return this.with(name, DEFAULT_CRITERIA[name])
  }

  /**
   * Build those criteria
   * @param {boolean} excludeDefault determines whether we should exclude the default values
   */
  build(excludeDefault = false) {
    const entries = Object.entries(this.values).filter(
      ([k, v]) => !excludeDefault || v !== DEFAULT_CRITERIA[k]
    )
    return Object.fromEntries(entries)
  }
}

/**
 * Helper to create a search
 */
export class SearchBuilder {
  constructor(type = 'bundle') {
    switch (type) {
      case 'bundle':
        {
          this.type = type
          this.maxSlices = 2
        }
        break

      case 'multi':
        {
          this.type = type
          this.maxSlices = Number.MAX_SAFE_INTEGER
        }
        break

      default:
        throw new Error('Unknown search type ' + type)
    }

    // init some fields
    this.slices = []
    this.passengers = []
    this.cabinClass = 'economy'
    this.ignoreSpecialOffers = true
    this.criteriaBuilder = null
    this.partner = null
  }

  /**
   * Clear all slices
   * @returns {SearchBuilder}
   */
  clearSlices() {
    this.slices.length = 0
    return this
  }

  /**
   * Add a slice
   *
   * @param {string} origin
   * @param {string} destination
   * @param {string} departureDate
   * @returns {SearchBuilder}
   */
  addSlice(origin, destination, departureDate) {
    if (this.slices.length < this.maxSlices) {
      if (origin && destination && departureDate) {
        this.slices.push({
          origin,
          destination,
          departure_date: departureDate,
        })
      }
    }

    return this
  }

  /**
   * Clear all passengers
   * @returns {SearchBuilder}
   */
  clearPassengers() {
    this.passengers.length = 0
    return this
  }

  /**
   * Add the number (nb) of passengers of the given type
   * @param {string} type
   * @param {number} nb
   * @returns {SearchBuilder}
   */
  addPassenger(type, nb = 1) {
    for (let i = 0; i < Math.max(0, nb); i++) {
      this.passengers.push({ type })
    }
    return this
  }

  /**
   * Set the ingore special offer flag
   * @param {boolean} flag
   * @returns {SearchBuilder}
   */
  withIngoreSpecialOffers(flag) {
    this.ignoreSpecialOffers = flag
    return this
  }

  /**
   * Clear and add two slices
   * @returns {SearchBuilder}
   */
  withRoundTrip(origin, destination, departureDate, returnDate) {
    return this.clearSlices()
      .addSlice(origin, destination, departureDate)
      .addSlice(destination, origin, returnDate)
  }

  /**
   * Clear and add one slice
   * @returns {SearchBuilder}
   */
  withOneWay(origin, destination, departureDate) {
    return this.clearSlices().addSlice(origin, destination, departureDate)
  }

  /**
   * Clear and add the given passengers
   *
   * @param {number} nbAdults
   * @param {number} nbChildren
   * @param {number} nbInfants
   * @returns {SearchBuilder}
   */
  withPassengers(nbAdults = 1, nbChildren = 0, nbInfants = 0) {
    return this.clearPassengers()
      .addPassenger('adult', nbAdults)
      .addPassenger('child', nbChildren)
      .addPassenger('infant', nbInfants)
  }

  /**
   * With the given cabin class
   * @param {string} cabinClass
   * @returns {SearchBuilder}
   */
  withCabinClass(cabinClass) {
    this.cabinClass = cabinClass
    return this
  }

  /**
   * Use the given criteria builder
   * @param {CriteriaBuilder} criteriaBuilder
   */
  withCriteriaBuilder(criteriaBuilder) {
    this.criteriaBuilder = criteriaBuilder
    return this
  }

  /**
   * With the given criteria
   */
  withCriteria(criteria) {
    return this.withCriteriaBuilder(new CriteriaBuilder(criteria))
  }

  /**
   * With the given partner
   * @param {string|object} partner
   */
  withPartner(partner) {
    if (typeof partner === 'string') {
      this.partner = { name: partner, visited_at: new Date().getTime() }
    } else {
      this.partner = partner
    }
    return this
  }

  build() {
    return {
      type: this.type,
      slices: this.slices,
      passengers: this.passengers,
      cabin_class: this.cabinClass,
      ignore_special_offers: this.ignoreSpecialOffers,
      available_partnerships: this.partner ? [this.partner] : [],
      criteria: (this.criteriaBuilder || new CriteriaBuilder()).build(true),
    }
  }
}

/**
 * Creates a new search builder
 * @param {string} type
 * @returns {SearchBuilder}
 */
export function newSearchBuilder(type) {
  return new SearchBuilder(type)
}

/**
 * Create a new criteria builder
 * @param {object} criteria
 * @returns {CriteriaBuilder}
 */
export function newSearchCriteriaBuilder(criteria = {}) {
  return new CriteriaBuilder(criteria)
}

/**
 * Create a search from the given offerRequest, recieved from the backend
 * @param {object} offerRequest
 * @param {boolean} resetDestructiveCriteria reset criteria that may interfer with the results of a new search
 * @returns {object}
 */
export function createSearchFromOfferRequest(
  offerRequest,
  resetDestructiveCriteria = true
) {
  const builder = newSearchBuilder(offerRequest.type)
    .withCabinClass(offerRequest.cabin_class)
    .withIngoreSpecialOffers(offerRequest.ignore_special_offers)

  // add slices
  offerRequest.slices.forEach((s) =>
    builder.addSlice(
      s.origin.iata_code,
      s.destination.iata_code,
      s.departure_date
    )
  )

  // add passengers
  offerRequest.passengers.forEach((p) => builder.addPassenger(p.type))

  // build the criteria
  // keep only the existing keys
  const criteria = new CriteriaBuilder()
  Object.entries(offerRequest.criteria).forEach(([k, v]) => {
    if (DEFAULT_CRITERIA[k] !== undefined) {
      criteria.with(k, v)
    }
  })

  // FIXME set them
  criteria.reset('departure_hour').reset('arrival_hour')

  // reset exclusions and inclusions if asked
  // otherwise the new search will not have the correct list of airlines
  if (resetDestructiveCriteria) {
    criteria
      .reset('include_airlines')
      .reset('exclude_airlines')
      .reset('include_airports')
      .reset('exclude_airports')
      .reset('max_stopover')
  }

  // set criteria
  builder.withCriteriaBuilder(criteria)

  // finally build the search
  return builder.build()
}
