import Image from 'next/legacy/image'
import Link from 'next/link'

// Components
import TrustpilotScore from '@/components/TrustpilotScore'
import Trans from '@/components/i18n/Trans'

// Hooks
import useTranslation from '@/hooks/translation'

// Assets
import ArrowButton from '@/assets/img/home/arrow-button.svg'
import TrustpilotStars from '@/assets/img/home/trustpilot.svg'
import Ancv from '@/assets/img/home/ancv.png'
import Ballon from '@/assets/img/home/ballon.png'

const CUSTOMERS = ['luciecharlotte', 'camille', 'matthieu']

function HomeReassurance() {
  const { t } = useTranslation()

  return (
    <section className="bg-white container py-50 md:py-80">
      <div className="row mb-30 md:mb-90">
        <div className="md:col-4 mb-30">
          <article className="pr-50">
            <h4 className="mb-20 font-bold leading-none text-26">
              <Trans
                i18nKey={'components.reassuranceBlock.benefits.account.title'}
                components={{
                  br: <br />,
                  span: <span className="text-primary" />,
                }}
              />
            </h4>
            <p className="text-gray-60">
              {t('components.reassuranceBlock.benefits.account.content')}
            </p>
          </article>
        </div>
        <div className="md:col-4 mb-30">
          <article className="pr-50">
            <h4 className="mb-20 font-bold leading-none text-26">
              <Trans
                i18nKey={'components.reassuranceBlock.benefits.simple.title'}
                components={{
                  br: <br />,
                  span: <span className="text-primary" />,
                }}
              />
            </h4>
            <p className="text-gray-60">
              {t('components.reassuranceBlock.benefits.simple.content')}
            </p>
          </article>
        </div>
        <div className="md:col-4 mb-30">
          <article className="pr-50">
            <h4 className="mb-20 font-bold leading-none text-26">
              <Trans
                i18nKey={
                  'components.reassuranceBlock.benefits.customerSupport.title'
                }
                components={{
                  br: <br />,
                  span: <span className="text-primary" />,
                }}
              />
            </h4>
            <p className="text-gray-60">
              {t(
                'components.reassuranceBlock.benefits.customerSupport.content'
              )}{' '}
              <a
                href="https://www.trustpilot.com/review/ulysse.com"
                target={'_blank'}
                rel="noreferrer"
                className="text-primary underline"
              >
                {t('components.reassuranceBlock.benefits.customerSupport.link')}
              </a>
            </p>
          </article>
        </div>
      </div>
      <h2 className="mb-20 text-26 text-gray-60">
        {t('components.reassuranceBlock.block2.title')}
      </h2>
      <h3 className="mt-3 font-bold text-dark leading-none text-40 md:w-7/12 mb-50">
        <Trans
          i18nKey={'components.reassuranceBlock.block2.subtitle'}
          components={{
            br: <br />,
            span: <span className="text-primary" />,
          }}
        />
      </h3>

      <div className="row mb-30 md:mb-90">
        <div className="md:col-6 mb-30">
          <Link href={'/ancv'} passHref>
            <article className="relative md:pb-[300px] p-50 rounded-10 bg-gray-light motion-safe:transition-transform hover:translate-y-10">
              <Image
                src={Ancv}
                layout="fill"
                objectFit="cover"
                quality={100}
                placeholder="blur"
                className="!hidden md:!block"
                alt=""
              />
              <h4 className="text-26 font-bold mb-10 text-dark">
                {t('components.reassuranceBlock.block2.ancv.title')}
              </h4>
              <p className="text-gray-60 text-22 md:mb-20">
                {t('components.reassuranceBlock.block2.ancv.content')}
              </p>
              <ArrowButton className="hidden md:block md:absolute bottom-50 right-50" />
            </article>
          </Link>
        </div>
        <div className="md:col-6 mb-30">
          <Link href={'/referral'} passHref>
            <article className="relative md:pb-[300px] p-50 rounded-10 bg-gray-light motion-safe:transition-transform hover:translate-y-10">
              <Image
                src={Ballon}
                layout="fill"
                objectFit="cover"
                quality={100}
                placeholder="blur"
                className="!hidden md:!block"
                alt=""
              />
              <h4 className="text-26 font-bold mb-10 text-dark">
                {t('components.reassuranceBlock.block2.referral.title')}
              </h4>
              <p className="text-gray-60 text-22 md:mb-20">
                {t('components.reassuranceBlock.block2.referral.content')}
              </p>
              <ArrowButton className="hidden md:block md:absolute bottom-50 right-50" />
            </article>
          </Link>
        </div>
      </div>

      <div className="flex justify-between">
        <h3 className="mt-3 font-bold text-dark leading-none text-40 md:w-7/12 mb-60">
          <Trans
            i18nKey={'components.reassuranceBlock.customerBlock.title'}
            components={{
              br: <br />,
              span: <span className="text-primary" />,
            }}
          />
        </h3>
        <div className="hidden md:block items-start pt-5">
          <TrustpilotScore />
        </div>
      </div>

      <div className="row">
        {CUSTOMERS.map((customer) => {
          return (
            <div key={customer} className="md:col-4 mb-30">
              <Feedback customerName={customer} />
            </div>
          )
        })}
      </div>
    </section>
  )
}

function Feedback({ customerName }) {
  const { t } = useTranslation()

  return (
    <article className="rounded-10 border border-gray-10 p-32">
      <header className="flex mb-20">
        <Image
          src={`https://storage.googleapis.com/ulysse-www/img/feedbacks/${customerName}.jpg`}
          width="60px"
          height="60px"
          alt=""
          className="rounded-full"
        />
        <h4 className="ml-20 mt-5 font-bold text-dark-80">
          <Trans
            i18nKey={`components.reassuranceBlock.customerBlock.${customerName}.author`}
            components={{
              br: <br />,
            }}
          />
        </h4>
      </header>
      <p className="text-dark-60 mb-20">
        {t(`components.reassuranceBlock.customerBlock.${customerName}.content`)}
      </p>
      <TrustpilotStars width={95} height={17} />
    </article>
  )
}

export default HomeReassurance
